<template>
  <BaseModal
    v-model="showPaymentQRModal"
    minHeight="150"
    maxWidth="450"
    minWidth="450"
    full-width-actions
    @cancel="close"
    @click-outside="close"
    @before-open="checkQR"
    cancelLabel="Close"
    :hasConfirm="false"
    :hasCancel="false"
    class="payment-qr-code-modal"
  >
    <div class="contents d-flex flex-column align-items-center">
      <div class="header d-flex align-items-center pt-2">
        Pay via
        <img
          class="ml-2"
          v-if="paymentQRDetails?.payment"
          :src="`/images/payments/${paymentQRDetails?.payment.toLowerCase()}.png`"
        />
      </div>

      <div class="amount mt-2">
        ₱{{ $filters.formatPrice(paymentQRDetails?.amount) }}
      </div>

      <div class="d-flex flex-column align-items-center">
        <template v-if="!paymentQRDetails?.invoiceUrl">
          <div
            class="preloader d-flex align-items-center justify-content-center mt-3"
          >
            <div class="spinner-border spinner-border-sm"></div>
            <div class="generting ml-2">Generating QR Code</div>
          </div>
        </template>

        <template v-else>
          <div class="paid" v-if="paid">
            <i class="fas fa-check-circle"></i>
          </div>

          <p class="note">
            Scan using your phone camera
            <br />
            <span
              >Note: This will not work if scanned with an e-wallet
              application</span
            >
          </p>

          <qr-code class="mt-2" :text="paymentQRDetails?.invoiceUrl" />

          <div class="scan-banner mt-4" :class="{ success: paid }">
            {{ bannerText }}
          </div>

          <div class="ref mt-5 d-flex">
            <div v-if="paymentQRDetails?.bill">
              Bill#: {{ paymentQRDetails?.bill }}
            </div>

            <div v-if="paymentQRDetails?.table">
              | Table#: {{ paymentQRDetails?.table }}
            </div>

            <div v-if="paymentQRDetails?.externalId">
              | Ref#: {{ paymentQRDetails?.externalId }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/spa/components/modals/BaseModal";
import PaymentQRMixin from "@/spa/components/mixins/PaymentQRMixin";
import { PAYMENT_INVOICE_STATUSES } from "@/spa/constants";

export default {
  name: "BillQrCodeModal",

  components: { BaseModal },
  mixins: [PaymentQRMixin],

  computed: {
    paid() {
      return this.paymentQRDetails?.status === PAYMENT_INVOICE_STATUSES.PAID;
    },

    bannerText() {
      return this.paid ? "Paid" : "Scan to Pay";
    },
  },

  methods: {
    close() {
      this.setShowPaymentQRModal(false);

      setTimeout(() => {
        this.setPaymentQRDetails(null);
      }, 500);
    },

    checkQR() {
      if (this.paymentQRDetails && !this.paymentQRDetails.invoiceUrl) {
        const params = {
          paymentChannel: this.paymentQRDetails.xendItPaymentChannel,
          orderId: this.paymentQRDetails.orderId,
          billNum: this.paymentQRDetails.bill,
          amountPaid: this.paymentQRDetails.amount,
          paymentType: this.paymentQRDetails.payment.toLowerCase()
        };

        this.createPaymentInvoice(params);
      }
    },
  },
};
</script>

<style scoped>
.header {
  font-size: 30px;
  font-weight: bold;
}

.header img {
  height: 50px;
}

.payment-qr-code-modal {
  font-size: 15px;
}

.amount {
  font-size: 30px;
  font-weight: bold;
  color: green;
  letter-spacing: 0.1rem;
}

.note {
  text-align: center;
  margin: 0;
}
.note span {
  font-size: 13px;
}

.contents {
  padding: 0 5px;
}

.status {
  font-weight: bold;
  color: orange;
}
.status.paid {
  color: green;
}

.preloader {
  width: 256px;
  height: 256px;
  opacity: 0.8;
}

.scan-banner {
  font-weight: bold;
  font-size: 32px;
  background-color: #135a96;
  color: white;
  border-radius: 10px;
  width: 260px;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
}

.scan-banner.success {
  background-color: green;
}

.ref {
  font-size: 13px;
  gap: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.generting {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
}

.paid {
  color: green;
  font-size: 35px;
  position: absolute;
  top: 10px;
  right: 10px;
}

::v-deep(.modal__title) {
  margin: 0 !important;
}
::v-deep(.modal__action) {
  padding: 0 !important;
}
</style>
