<script>
import {
  getPaymentInvoices,
  createPaymentInvoice,
  cancelPaymentInvoice,
} from "@/spa/services/cashier-service";
import NetworkMixin from "@/spa/components/mixins/NetworkMixin";
import {mapActions, mapMutations, mapState} from "vuex";
import {isNetworkStable} from "@/spa/utils/networkCheck";
import {OFFLOAD} from "@/spa/constants";
import {isEmpty} from "lodash";
import bus from '@/spa/utils/bus';

export default {
  mixins: [NetworkMixin],

  data() {
    return {
      fetchPaymentInvoicesInterval: null,
      billQrCodeDetails: null,
      revalidateTimer: 5000,
      paymentInvoicesResponse: null,
    };
  },

  computed: {
    ...mapState("global", ["showPaymentQRModal", "paymentQRDetails"]),

    ePaymentInvoices() {
      return this.paymentInvoicesResponse?.map((payment) =>
        this.mapPaymentInvoice(payment)
      );
    },
  },

  methods: {
    ...mapMutations("global", ["setShowPaymentQRModal", "setPaymentQRDetails"]),
    ...mapMutations(["updateOrder"]),
    ...mapActions(["sqliteUpsertReceipt"]),

    generatePaymentQR(params) {
      this.setPaymentQRDetails(params);

      this.setShowPaymentQRModal(true);
    },

    startAutoRevalidation({ status }) {
      if (!isNetworkStable() || !window.hasEPayment) {
        return;
      }

      this.getPaymentInvoices({ status });

      clearInterval(this.fetchPaymentInvoicesInterval);

      this.fetchPaymentInvoicesInterval = setInterval(() => {
        if (this.isOnline) {
          this.getPaymentInvoices({ status });
        }
      }, this.revalidateTimer);
    },

    async getPaymentInvoices({ status }) {
      try {
        const orderId = this.$route.params.orderId;
        const params = { orderId, status };
        const response = await getPaymentInvoices(params);

        if (OFFLOAD.sqliteOffloadReceipt) {
          const storage = new ScopedNativeStorage(window.locationId);
          const invoices = response.data.filter(item => item.orderId == orderId && item.status === 'PAID' && item?.isFullyPaid);

          if (invoices.length > 0) {
            storage.put('appLatestId', orderId);
          }
        }

        this.paymentInvoicesResponse = response.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async createPaymentInvoice(params) {
      try {
        if (OFFLOAD.sqliteOffloadReceipt) {
          const ob = new OrderBridge();
          const order = await ob.getOrderById(parseInt(params.orderId));

          if (isEmpty(order)) {
            throw new Error('Failed to retrieve order data.');
          }

          params.order = order.order_data;
        }

        const response = await createPaymentInvoice(params);

        const payment = response.data.data;

        this.setPaymentQRDetails({
          ...this.paymentQRDetails,
          invoiceUrl: payment.invoiceUrl,
          externalId: payment.externalId,
        });

        this.$emit("qrGenerated", this.mapPaymentInvoice(payment));
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        throw e;
      }
    },

    async cancelPaymentInvoice(externalId) {
      try {
        await cancelPaymentInvoice(externalId);
      } catch (e) {
        this.$swal.error("Something went wrong", "Please try again");
        throw e;
      }
    },

    mapPaymentInvoice(payment) {
      return {
        id: payment?.paymentTypeId,
        payment_invoice_id: payment.id,
        externalId: payment.externalId,
        amount: payment.amount,
        exact_amount: payment.amount,
        change: 0,
        method: `E-PAYMENT (${payment.paymentChannel})`,
        type: "epayment",
        invoiceUrl: payment.invoiceUrl,
        status: payment.status,
        paymentChannel: payment.paymentChannel,
        paymentType: payment.paymentType,
        billNum: payment.billNum,
      };
    },

    updateActivePaymentQR(payment) {
      if (!this.showPaymentQRModal) {
        return;
      }

      if (this.paymentQRDetails.externalId === payment.externalId) {
        this.setPaymentQRDetails({
          ...this.paymentQRDetails,
          status: payment.status,
        });
      }
    },
  },

  unmounted() {
    clearInterval(this.fetchPaymentInvoicesInterval);
  },
};
</script>
