<script>
import { print } from "@/spa/services/printer-service";
import { map, uniq, get, uniqBy, mapValues, cloneDeep, isEmpty, isNil } from "lodash";
import { mapState, mapMutations } from 'vuex';
import { getReceiptDetails } from "@/spa/services/cashier-service";
import kotHtml from '@/spa/components/templates/print/kot.html';
import beginningBalHtml from '@/spa/components/templates/print/beginning_balance.html';
import { convert } from 'html-to-text';
import { getReceiptPrintString, getVoidPrintString, getQrPaymentPrintString } from "@/spa/utils/print";
import {OFFLOAD, STATUSES} from "@/spa/constants";
import seriesService from "@/spa/services/series-service";
import { Logger } from "@/spa/helpers/Logger";
import {OrderBridge} from "@/mobile_bridge/offload/offload-receipt";

export default {
    computed: {
        ...mapState({ originalOrders: state => state.orders }),
        ...mapState(['dataContainer', 'lastVoidBillNum', 'lastVoidReceiptNum', 'lastReceiptNum']),
        ...mapState('user', ['username']),
    },

    methods: {
        ...mapMutations(['updateOrder']),

        printAllKOT(orderId, kots, reprint = false, isVoided = false) {
            kots.forEach(kot => this.printKOT({ parentOrderId: orderId, kot }, null, isVoided, reprint));
        },

        //TODO: copy-paste from BillSettings.vue - needs refactoring
        //TODO: convert this to named arguments
        async printKOT(transactionItem, transItemObj = null, isVoided = false, reprint = false) {
            const ob = new OrderBridge();

            if(transactionItem == null) {
                transactionItem = transItemObj;
            }

            let orderDetail;
            if (OFFLOAD.sqliteOffloadReceipt) {
                const response = await ob.getOrderById(parseInt(transactionItem.parentOrderId));
                orderDetail = JSON.parse(response.order_data);
            } else {
                orderDetail = uniqBy([...this.orders, ...this.originalOrders.filter(o => o && (!o.isSync || o.isOnlineDelivery))], '_id')
                    .find(o => o
                        && o._id == transactionItem.parentOrderId
                        && (o.originShiftTable?.pos_date == this.posDate || o.isOnlineDelivery)
                    );
            }

            let receiptDetails = await this.getReceiptDetails(orderDetail);
            let kotPrintObj = this.getKOTPrintObj(transactionItem, orderDetail, receiptDetails, isVoided, reprint);
            let kotHTMLContent = kotPrintObj.printTemplate;

            let printData = {
                print_type: 'KOT',
                html: kotHTMLContent,
            };

            let printConfig = {
                location_id: receiptDetails.location_id,
                kotItemObject: kotPrintObj.kotItemObject,
            };

            Logger.info(`Print Mixin Receipt: printKOT`)

            print(printData, printConfig);
        },

        //TODO: copy-paste from BillSettings.vue - needs refactoring
        getKOTPrintObj(transactionItem, orderDetail, receiptDetails, isVoidItem = false, reprint = false) {
            let printTemplate = kotHtml;

            //initialize kotItemObject. this is for category based printing
            let kotItemObject = {};
            //get product categories and put them in an array
            let orderProductCategories = uniq(map(orderDetail.orders, o => get(o, 'product.product_group.product_category_id')));

            orderProductCategories.forEach(function(value){
                kotItemObject[value] = '';
            });

            //generate kot items
            let kotItemsStr = orderDetail.orders.map(function(order) {
                if(order && order.kot == transactionItem.kot) {

                    let returnString =  `<span class="fs-17">${order.quantity}  ${order.product.product_name}</span><br>
                    ${order.product.hasModifier == 1 ?
                    `${order.product.forcedMods.map(function (fmod) {
                        return `<span class="mods ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">&nbsp;&nbsp;${fmod.quantity} ${fmod.modifier_name}</span><br>`
                    }).join(' ')}${order.product.unforcedMods.map(function (ufmod) {
                        return `<span class="mods ${order?.isVoided ? 'kot-voided-item' : 'kot-item'}">&nbsp;&nbsp;${ufmod.quantity} ${ufmod.modifier_name}</span><br>`
                    }).join(' ')}` : ''}
                    ${order.request != undefined && order.request != null && order.request.length > 0 ?
                    `<span class="reqs">&nbsp;&nbsp;${order.request}</span><br>` : ''}
                    ${order.isVoided == true ? `<span>- voided</span><br>` : ''}
                    `;

                    let categoryId = order.product.product_group?.product_category_id
                    kotItemObject[categoryId] = kotItemObject[categoryId].concat(returnString);

                    return returnString;
                }
            }).join("");

            //assign the kotItemStr to default key in kotItemObject
            kotItemObject['default'] = kotItemsStr;


            let service_type = orderDetail.serviceType == "Delivery" ? "Delivery" : "Table";
            let identifier = orderDetail.tableName == undefined ? orderDetail.tableId : orderDetail.tableName;

            let identifierString = "";
            switch (receiptDetails.account_type_id) {
                case 4:
                    identifierString = orderDetail.brandId + "-" + this.dataContainer['qsr_' + orderDetail._id];
                    break;
                case 2:
                    if(orderDetail.serviceType == "Delivery") {
                        identifierString = orderDetail.brandId + "-" + this.dataContainer['bm_del_' + orderDetail._id];
                    } else {
                        identifierString = orderDetail.serviceType.replace("-", " ") + "-" + identifier;
                    }
                    break;
                default:
                    identifierString = orderDetail.serviceType.replace("-", " ") + "-" + identifier;
            }

            if(orderDetail.isOnlineDelivery) {
                identifierString = orderDetail.shortOrderId ?? orderDetail.channelName;
            }

            let today = new Date();
            let date =
                today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
            let time =
                ("0" + today.getHours()).substr(-2) + ":" + ("0" + today.getMinutes()).substr(-2) + ":" + ("0" + today.getSeconds()).substr(-2);
            let dateTime = date + " " + time;

            let toReplace = {
                __REPRINT__: isVoidItem || !reprint ? '' : '<span style="font-weight: bold;">REPRINT</span><br>',
                __ORDERIDENTIFIER__: service_type +" : "+ identifierString,
                __DATETIME__: dateTime,
                __ORDERNUM__: transactionItem.kot,
                __CASHIERNAME__: receiptDetails.cashier_name,
                __PAXCOUNT__: orderDetail.pax,
                __BRANDNAME__: receiptDetails.brand_name,
                __LOCATIONNAME__: receiptDetails.location,
                __MOREDETAILS__: orderDetail.orderDetail ? 'Reference: ' + orderDetail.orderDetail : '',
                __SERVICETYPE__: orderDetail.serviceType.toUpperCase(),
                __KOTVOID__: isVoidItem ? '<span style="font-weight: bold;">KOT - VOID</span><br>' : '',
            };

            var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
            printTemplate = printTemplate.replace(RE, function(matched) {
                return toReplace[matched];
            });

            console.log(
                convert(printTemplate.replace('__KOTITEMS__', kotItemsStr),
                    {
                        wordwrap: 130,
                        preserveNewlines: true,
                    }
            ));

            return {printTemplate, kotItemObject};
        },

        async getReceiptDetails(order) {
            try {
                if (OFFLOAD.sqliteOffloadReceipt && OFFLOAD.sqliteOffloadPOSFE1300) {
                    const receiptDetailBridge = new ReceiptDetailBridge();
                    return await receiptDetailBridge.getRowByLocationId(window.locationId);
                }

                let receiptResponse = await getReceiptDetails();
                let details = Object.values(receiptResponse.data).find(d=>d.brand_id == order.brandId);
                return details;
            } catch (error) {
                console.log(error);
            }

            return {};
        },

        //TODO: copy-paste from BillSettings.vue - needs refactoring
        async printReceipt(type, order, qrcode = null) {
            // If the order is paid via E-PAYMENT, we will automatically print OR and we need to generate receipt_num based on the latest available
            if(!OFFLOAD.sqliteOffloadReceipt && !order.receipt_num && type === 'settlement') {
                const lastReceiptNum = await seriesService.getLastReceiptNum() + 1;
                order = {...order, receipt_num: lastReceiptNum};

                seriesService.setLastReceiptNum(lastReceiptNum);

                this.updateOrder({
                    orderId: order._id,
                    order
                });
            }

            if (OFFLOAD.sqliteOffloadReceipt) {
                const ob = new OrderBridge();
                const response = await ob.getOrderById(parseInt(order._id));
                if (!isEmpty(response)) {
                    order = JSON.parse(response.order_data);
                }

                if (isNil(order?.receipt_num) && qrcode == null) {
                    // Adding a delay of 3 seconds before retrying
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    return await this.printReceipt(type, order);
                }
            }

            let receiptArgs = await this.generateReceiptArgs(order);
            receiptArgs.mode = type;

            let receiptString = type === 'qr' ? await getQrPaymentPrintString() : await getReceiptPrintString(order, receiptArgs, qrcode) ;
            let receiptDetails = await this.getReceiptDetails(order);
            let printData = {
                print_type: type === 'qr' ? 'QRPayment' :  'Receipt',
                html: receiptString,
                mode: receiptArgs.mode,
            };
            let printConfig = {
                location_id: receiptDetails.location_id,
            };

            if(type === 'qr') {
                Logger.info(`Print Mixin ${type} Receipt: printReceipt`)
                print(printData, printConfig, null, qrcode);
                return;
            }

            const locIds = JSON.parse(sessionStorage.getItem('new_bill_or_enabled_location_ids'))
            
            if (locIds.includes(receiptDetails.location_id)) {
                const {data: {printer_details, printer_models}} = await getPrinterIps();
                printer_details.filter(p => p.can_print_receipt === 1 || p.can_print_bill === 1)
                .forEach(printer => {
                    const printerModel = printer_models.find(pm => pm.id === printer.printer_model_id)
                    const _epos = new Epos({
                    ip: printer.ip_address,
                    model: printerModel.printer_method,
                    width: printer.paper_width
                    });
                    _epos.print(BILL_OR, {...order, is_paid: !result.isConfirmed}, STANDALONE_MODE)
                })
            } else {
                print(printData, printConfig, null, qrcode);
                Logger.info(`Done receipt printing: printReceipt`)
            }
        },

        //TODO: copy-paste from BillSettings.vue - needs refactoring
        async generateReceiptArgs(order) {
            let discountContainer = [];

            let paymentsArr = order.payments ?? [];

            if(order.splits) {
            paymentsArr = [];
            order.splits.filter(s => s.bill_num == order.bill_num).forEach(s => {
                paymentsArr.push(...s.payments);
                s.discounts.forEach(d => {
                discountContainer.push({discountAmount: d.amount, discountPax: d.discountQuantity, ...d.customerDetails[0], ...d.discount});
                });
            });
            } else if(order.billDiscount) {
                discountContainer.push({discountAmount: order.billDiscount.amount, discountPax: order.billDiscount.discountQuantity, ...order.billDiscount.customerDetails[0], ...order.billDiscount.discount});
            } else {
                //get line item discount
                discountContainer.push(...this.lineItemDiscounts(order));
            }

            let receiptArgs = {
                formattedTotals: this.formatTotals(order.totals),
                tableName: order.tableName,
                receiptDetails: await this.getReceiptDetails(order),
                customerDetails: order.customerDetails,
                discountContainer: discountContainer,
                payments: paymentsArr,
                dataContainer: this.dataContainer,
                activeBrandId: order.brandId,
                serviceType: order.serviceType,
                bill_num: order.bill_num,
                receipt_num: order.receipt_num,
                mode: 'bill',
                reprint_string: '',
                copyHeader: ''
            };

            return receiptArgs;
        },

        lineItemDiscounts(order) {
            return order.orders.reduce((ret, o) => {
                if(o.discount) {
                    ret.push(o.discount);
                }

                return ret;
            }, []);
        },

        formatTotals(totals) {
            return mapValues(totals, v => v !== null ? this.$filters.formatPrice(v) : v);
        },

        printAllVoidedReceipts(orderId, kots, reprint = false) {
            // print void KOT
            kots.forEach(kot => this.printKOT({ parentOrderId: orderId, kot }, null, true, reprint));

            // print void bill
            const order = this.originalOrders.find(order => order._id === orderId);
            this.printDeliveryVoidBilled(order);
        },

        // print void KOT and void Bill at the same time 
        // since once a delivery order is accepted it it will print KOT and tagged as billed
        printDeliveryVoidKOT(order, reason) {
            this.printDeliveryPendingVoidKOT(order, reason)
            this.printDeliveryVoidBilled(order)
        },

        printDeliveryPendingVoidKOT(order, reason) {
            const orderClone = cloneDeep(order);
            orderClone.orders = orderClone.orders.map((order) => ({
                ...order,
                isVoided: true,
            }));

            const transaction = {
                parentOrderId: order._id,
                kot: order.kots[0],
            };

            this.updateOrder({
                orderId: order._id,
                order: {
                    ...orderClone,
                    isVoided: true,
                    updateType: "void",
                    voidReason: reason,
                },
            });

            this.printKOT(transaction, null, true);
        },

        async printDeliveryVoidBilled(order) {
            const void_bill_num = this.lastVoidBillNum;
            let receiptArgs = await this.generateReceiptArgs(order);
            receiptArgs.mode = "void_bill";
            receiptArgs.void_refnum = void_bill_num;

            const voidSlipHTML = await getVoidPrintString(order, receiptArgs);

            //replace text for modal display
            let html = voidSlipHTML;
            html = html.replace(/__BLANK__/ig, ' ');
            html = html.replace(/__MOD__/ig, ' ');

            const receiptDetails = await this.getReceiptDetails(order);
            const printData = {
                print_type: 'Void',
                html: voidSlipHTML,
                mode: receiptArgs.mode,
            };
            const printConfig = {
                location_id: receiptDetails.location_id,
            };

            Logger.info(`Print Mixin from: printDeliveryVoidBilled`);

            print(printData, printConfig);
        },

        getStatusString(order) {
            if (order.isVoided) return STATUSES.VOID;
            if (order.isSettled) return STATUSES.PAID;
            if (order.isBilled) return STATUSES.BILLED;
            return STATUSES.PENDING;
        },

        async printFloatReceipt(float) {
            Logger.info(`Print Mixin from: printFloatReceipt`);

            let printTemplate = '';
            let __CASHIERNAME__ = float.cashier?.username ?? float.user?.username;
            if (OFFLOAD.sqliteOffloadReceipt) {
                __CASHIERNAME__ = this.username;
            }
            const toReplace = {
                ___ACCOUNT_NAME___: float?.account_name,
                ___LOCATION___: float?.location_name,
                ___DATETIME___: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                __TYPE__: float.type,
                __AMOUNT__: parseFloat(float.amount),
                __MANAGERNAME__: float.manager?.username ?? '',
                __CASHIERNAME__,
            }

            var RE = new RegExp(Object.keys(toReplace).join("|"), "gi");
            printTemplate = beginningBalHtml.replaceAll(RE, function(matched) {
                return toReplace[matched];
            });

            console.log(
                convert(printTemplate,
                    {
                        wordwrap: 130,
                        preserveNewlines: true,
                    })
            );

            const printData = {
              print_type: 'CashFloat',
              html: printTemplate,
            };

            print(printData, {});
        },
    },

    
};
</script>