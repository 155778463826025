import swal from 'sweetalert';

const swalWrapper = (title, text, icon) => swal({ title, text, icon });

export default {
    computed: {
        $swal() {
            return {
                success: (title, text = '') => swalWrapper(title, text, 'success'),
                error: (title, text = '') => swalWrapper(title, text, 'error'),
                warning: (title, text = '') => swalWrapper(title, text, 'warning'),
                info: (title, text = '') => swalWrapper(title, text, 'info'),
                confirm: (title, text = '', icon = 'info') => swal({ title, text, icon, buttons: true }),
            }
        },
    },
};
