<template>
  <div class="d-flex">
    <div class="payment-page pt-4 px-2 px-lg-5 col-lg-8 col-md-12">
      <label>Bills and Payments</label>

      <div v-if="canSplit" class="bills-header d-flex align-items-center">
        <label class="mr-4 mb-0">Split Type</label>
        <div class="btn-group flex-container d-flex flex-wrap" role="group">
          <button v-for="split in splitTypes" :disabled="isPaymentStep" :key="`split-${split}`" type="button"
            class="btn btn-sm split-btn" :class="{
              active: split === splitType,
              'bg-primary': split === splitType,
              'bg-light': split !== splitType,
            }" @click="splitType = split">
            {{ split }}
          </button>
        </div>
      </div>

      <div v-if="hasSplitCount" class="split-controls mt-3 d-flex align-items-center">
        <label class="mr-4 mb-0">Split Count</label>
        <div class="btn-group flex-container d-flex flex-wrap" role="group">
          <button :disabled="isPaymentStep" type="button" class="btn btn-sm" @click="subtractSplit">
            Subtract Bill
          </button>

          <button :disabled="isPaymentStep" type="button" class="btn btn-sm" @click="addSplit">
            Add Bill
          </button>

          <button :disabled="isPaymentStep" type="button" class="btn btn-sm" @click="combineSplits">
            Combine Bills
          </button>
        </div>
      </div>

      <button v-if="canSplit" class="btn btn-block btn-primary mt-4" :disabled="isPaymentStep" @click="startPayment">
        Start Payment
      </button>

      <m-payment-method-selector v-if="!canSplit" @selectPaymentMethod="
        openPaymentModalWithPreset($event);
      getSelectedPaymentObject($event);
      " />

      <hr />

      <div class="bills d-lg-flex flex-wrap" :class="{ hidden: !canSplit }">
        <div
          v-for="(splitBill, index) in splitBills"
          :key="`split-${index}`"
          class="p-2 col-lg-6 col-12"
        >
          <m-split-bill
            ref="splitBills"
            v-model:pax="splitPax[index]"
            :max-pax="activeOrder.pax"
            :items="splitBill.orders"
            :split-number="splitBill.bill_num"
            :service-type="serviceType"
            :isVoided="splitBill.isVoided"
            :settled="settledSplitIndices[index]"
            :show-move="isCustomSplit && !isPaymentStep"
            :move-disabled="!hasSelection"
            :is-payment-step="isPaymentStep"
            :selection="selectedItemsBySplit[index]"
            :can-settle="isFullyPaidSplits[index]"
            :discounts="splitDiscounts[index]"
            :payments="splitPayments[index]"
            :is-finish-payment="isPostCustomerDetail"
            consolidate-orders
            @totalsUpdated="updateTotalsByIndex(index, $event)"
            @itemsSelected="updateSelectionsByIndex(index, $event)"
            @moveClicked="moveSelectionToSplit(index)"
            @addPayment="openPaymentModal(index)"
            @addDiscount="openDiscountModal(index)"
            @settleBill="startSettleSplit(index)"
            @printBill="showReceipt(index, $event)"
            @showItemAction="triggerOrderpanelLineItemActions"
            @removeDiscount="removeDiscount(index, $event)"
            @removePayment="removePayment(index, $event)"
          />
        </div>
      </div>
    </div>


    <orders-panel ref="orders" :order-id="orderId" :orders="activeOrder.orders" :payments="payments"
      :discounts="discounts" :service-type="serviceType" :pax="activeOrder.pax" :table-id="activeOrder.tableId"
      :ds="activeOrder.kots" :order-detail="activeOrder.orderDetail" :force-single-bill="forceSingleBillUi"
      :split-totals="splitTotals" :split-discounts="splitDiscounts" :balance-override="aggregateBalance"
      :change-override="aggregateChange" payment consolidate-orders :enable-print-all="isPaymentStep"
      :is-finish-payment="isPostCustomerDetail" @finishPayment="postPaymentHandler(false, $event)"
      @balanceUpdated="updateTotalPayable" @removePayment="removePayment(0, $event)"
      @removePaymentInvoice="removePaymentInvoice" @removeDiscount="removeNonSplitDiscount" @printAll="printAllSplitBills"
      @saveRoomChargePayment="postRoomChargePayment" />


    <add-payment-modal v-if="splitTotals[targetSplitBillIndex] != undefined" v-model="isAddPaymentModalOpen"
      :preselected-payment-method="canSplit ? '' : presetPaymentMethodType" :total-payable="splitTotals[targetSplitBillIndex].total -
        totalPayments[targetSplitBillIndex]
        " @savePayment="addPayment" @generatePaymentQRInfo="generatePaymentQRInfo" ref="paymentModal"
      :canPrintQrCode="canPrintQrCode" />

    <bill-discount-modal v-model="isDiscountModalOpen" :pax="splitPax[targetSplitBillIndex]"
      :order="splitBills[targetSplitBillIndex]" @applyDiscount="applyDiscount" />

    <customer-details-modal v-model="isCustomerDetailsModalOpen" @saveCustomerDetails="postCustomerDetailsHandler"
      @skipCustomerDetails="postCustomerDetailsHandler" />

    <PaymentQrCodeModal @qrGenerated="qrGenerated" />
  </div>
</template>

<script>
import bus from '@/spa/utils/bus';
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import {
  every,
  filter,
  find,
  forEach,
  get,
  isEmpty,
  isEqual,
  map,
  mapValues,
  omit,
  pick,
  pullAt,
  some,
  uniq,
  differenceWith,
} from "lodash";
import cloneDeep from "rfdc/default";

import { getReceiptDetails } from "@/spa/services/cashier-service";
import {
  print,
  openCashDrawer,
  getPrinterData,
  getPrinterIps,
} from "@/spa/services/printer-service";

import OrdersPanel from "@/spa/components/panels/OrdersPanel";
import MSplitBill from "@/spa/components/common/MSplitBill";
import AddPaymentModal from "@/spa/components/modals/AddPaymentModal";
import BillDiscountModal from "@/spa/components/modals/BillDiscountModal";
import CustomerDetailsModal from "@/spa/components/modals/CustomerDetailsModal";
import MPaymentMethodSelector from "@/spa/components/common/MPaymentMethodSelector";

import {
  calculateDiscountAmount,
  redistributeTenders,
} from "@/spa/utils/computations";
import { toIsoString } from "@/spa/utils/date-format";
import {
  getReceiptPrintString,
  generateReceiptItemString,
} from "@/spa/utils/print";
import {
  OR_PRINT_HEADERS,
  PAYMENT_INVOICE_STATUSES,
  IS_PUC_LOCATION,
  OFFLOAD,
  ACTIVE_ACCOUNT_TYPE,
  ACCOUNT_TYPES,
  USE_NEW_AMOUNT_DUE_FORMULA,
} from "@/spa/constants";

import PaymentQrCodeModal from "@/spa/components/modals/PaymentQrCodeModal";
import PaymentQRMixin from "@/spa/components/mixins/PaymentQRMixin";
import PrintMixin from "@/spa/components/mixins/PrintMixin";
import OrderMixin from "@/spa/components/mixins/OrderMixin";
import seriesService from "@/spa/services/series-service";

import { checkCloudConnection } from "@/spa/plugins/axios";
import { getLatestSeries } from "@/spa/services/sync-service";
import CartPanel from "@/spa/components/panels/CartPanel";
import {getAmountDue, mergeAndUpdatePax, OFFLOAD_RECEIPT_ACTION} from "@/mobile_bridge/offload/offload-receipt";
import {formatAmount, posDateWithCurrentTime} from "@/mobile_bridge/offload/receipt-model";

export default {
  name: "PaymentPage",

  components: {
    MSplitBill,
    OrdersPanel,
    AddPaymentModal,
    BillDiscountModal,
    CustomerDetailsModal,
    MPaymentMethodSelector,
    PaymentQrCodeModal,

  },

  mixins: [PaymentQRMixin, PrintMixin, OrderMixin],

  props: {
    orderId: {
      type: String,
      required: true,
    },
    tableName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      totalPayable: 0,
      totalsBreakdown: {},
      payments: [],
      discounts: [],
      splitPayments: {},
      splitDiscounts: {},
      splitTotals: {},
      isAddPaymentModalOpen: false,
      isDiscountModalOpen: false,
      isCustomerDetailsModalOpen: false,
      splitType: "none",
      splitTypes: ["none", "equal", "category", "custom"],
      splitCount: 1,
      targetSplitBillIndex: 0,
      isPaymentStep: false,
      settledSplitIndices: {},
      selectedItemsBySplit: {},
      customSplit: {},
      receiptDetails: {},
      presetPaymentMethodType: "CASH",
      forceSingleBillUi: true,
      receiptArgs: {},
      selectedPaymentObject: null,
      splitPax: [],
      selectedPaymentMethods: [],
      printBill: false,
      canPrintQrCode: false,
      isPostPaymentProcessing: false,
      isPostCustomerDetail: false,
    };
  },


  computed: {


    ...mapState([
      "activeOrderId",
      "orders",
      "activeBrandId",
      "lastBillNum",
      "lastReceiptNum",
      "dataContainer",
      "billings",
      "settlements",
    ]),
    ...mapState("user", ["terminalId"]),
    ...mapState("settings", ["serviceTypes"]),
    ...mapGetters([
      "activeOrder",
      "activeMergees",
      "activePendingSettlements",
      "pendingOrders",
    ]),
    ...mapGetters("user", ["multiTerminalType"]),

    canSplit() {
      return !this.forceSingleBillUi;
    },

    splitBills() {
      if (this.isCustomSplit) {
        return this.customSplit;
      } else if (this.splitType === "equal" && this.splitCount > 1) {
        const splits = Array.from(new Array(this.splitCount));
        return splits.map((_, i) => {
          const billNum = Number(this.activeOrder.bill_num) + i;
          const splitValue = this.activeOrder.splits?.find(
            (s) => s.bill_num == billNum
          );
          const orderObj = splitValue ?? this.activeOrder;
          return {
            ...orderObj,
            bill_num: billNum,
            orders: orderObj.orders.map((o, i) => {
              let newTotals = {};
              const activeOrderOrder = this.activeOrder.orders[i];
              Object.entries(o.totals).forEach(([key, value]) => {
                newTotals[key] = value / this.splitCount;
              });

              return {
                ...o,
                splits: undefined,
                quantity: splitValue
                  ? o.quantity
                  : activeOrderOrder.quantity / this.splitCount,
                totals: newTotals,
                pax: this.defaultPerSplitPax,
              };
            }),
          };
        });
      } else if (this.splitType === "category") {
        const categories = uniq(
          this.activeOrder.orders.map((c) =>
            get(c, "product.product_group.product_category_id")
          )
        );

        const splits = categories.map((category, i) => {
          const billNum = Number(this.activeOrder.bill_num) + i;
          const splitValue = this.activeOrder.splits?.find(
            (s) => s.bill_num == billNum
          );
          const orderObj = splitValue ?? this.activeOrder;

          return {
            ...orderObj,
            splits: undefined,
            bill_num: billNum,
            orders: orderObj.orders.filter(
              (o) => o.product.product_group.product_category_id == category
            ),
            pax: this.defaultPerSplitPax,
          };
        });

        return splits;
      } else if (this.splitType === "dine/take-out") {
        const ordersByServiceTypeId = this.activeOrder.orders.reduce(
          (acc, curr) => {
            if (!acc[curr.serviceTypeId]) {
              acc[curr.serviceTypeId] = [];
            }
            acc[curr.serviceTypeId].push(curr);
            return acc;
          },
          {}
        );

        const separatedOrders = Object.keys(ordersByServiceTypeId).map(
          (serviceTypeId, i) => {
            return {
              ...this.activeOrder,
              serviceType:
                this.serviceTypes.find((i) => i.id == serviceTypeId)
                  ?.service_name || this.serviceType,
              serviceTypeId,
              bill_num: Number(this.activeOrder.bill_num) + i,
              orders: ordersByServiceTypeId[serviceTypeId],
            };
          }
        );

        return separatedOrders;
      } else {
        return [this.activeOrder];
      }
    },

    actualSplitCount() {
      if (this.hasSplitCount) {
        return this.splitCount;
      } else if (
        this.splitType === "category" ||
        this.splitType === "dine/take-out"
      ) {
        return this.splitBills.length;
      } else {
        return 1;
      }
    },

    defaultPerSplitPax() {
      return Math.floor(this.activeOrder.pax / this.splitCount) || 1;
    },

    aggregateBalance() {
      const splitIndices = Object.keys(this.splitBills);

      return splitIndices.reduce((acc, splitIndex) => {
        const total = this.splitTotals[splitIndex]?.total;
        const totalPayment = this.splitPayments[splitIndex]?.reduce(
          (acc, payment) => acc + payment.amount,
          0
        );

        return acc + Math.max(0, total - totalPayment);
      }, 0);
    },

    aggregateChange() {
      const splitIndices = Object.keys(this.splitBills);

      return splitIndices.reduce((acc, splitIndex) => {
        const total = this.splitTotals[splitIndex]?.total;
        const totalPayment = this.splitPayments[splitIndex]?.reduce(
          (acc, payment) => acc + payment.amount,
          0
        );

        return acc + Math.max(0, totalPayment - total);
      }, 0);
    },

    totalDiscounts() {
      return map(this.splitDiscounts, (discounts) =>
        discounts.reduce((acc, discount) => acc + discount.amount, 0)
      );
    },

    totalPayments() {
      return map(this.splitPayments, (payments) =>
        payments.reduce((acc, payment) => acc + payment.amount, 0)
      );
    },

    hasSelection() {
      return some(this.selectedItemsBySplit, (items) => !isEmpty(items));
    },

    isCustomSplit() {
      return this.splitType === "custom";
    },

    hasSplitCount() {
      return this.canSplit && ["equal", "custom"].includes(this.splitType);
    },

    isAllSettled() {
      return every(filter(this.settledSplitIndices, (v) => v !== "__DELETE__"));
    },

    isFullyPaidSplits() {
      if (!this.canSplit) return [];
      return map(this.splitTotals, (splitTotal, i) => {
        const totalPayment = get(this.totalPayments, i, 0);
        const totalDiscount = get(this.totalDiscounts, i, 0);
        return (
          this.roundToTwoDecimals(splitTotal.total) <=
          this.roundToTwoDecimals(totalPayment + totalDiscount)
        );
      });
    },

    serviceType() {
      return this.activeOrder.serviceType || "";
    },
  },

  async mounted() {

    this.setActiveOrderId(this.orderId);
    this.parseReceiptDetails();

    setTimeout(() => {
      this.parseTotalPayable();
    }, 50);

    await this.$nextTick();
    if (this.activeOrder.isSplitRequested) {
      this.forceSingleBillUi = false;
    }

    await this.$nextTick();
    if (!this.canSplit) {
      this.isPaymentStep = true;
    }

    await this.$nextTick();
    await this.loadSettlementState();

    const usedServiceTypes = this.activeOrder.orders
      .filter((o) => !o.isVoided)
      .map((o) => o.serviceName);
    const hasDineInAndTakeOut = ["Dine-in", "Take-out"].every((service) =>
      usedServiceTypes.includes(service)
    );
    if (hasDineInAndTakeOut) {
      this.splitTypes.push("dine/take-out");
    }

    const printers = await getPrinterIps();

    printers.data.printer_details.forEach((printer) => {
      if (printer.can_print_qr_code) {
        this.canPrintQrCode = true;
      }
    });

    if (this.$route.query?.isChargeToRoom) {
      const mewsOrder = localStorage.getItem("activeMewsOrder");
      if (mewsOrder) {
        try {
          this.postRoomChargePayment(JSON.parse(mewsOrder));
        } catch (e) {
          // do nothing
        }
      }
    }
  },

  watch: {
    activeOrder: {
      handler(value, oldValue) {
        if (isEqual(omit(value, ["updatedAt"]), omit(oldValue, ["updatedAt"])))
          return;
        if (!value) return;
        this.parseTotalPayable();
      },
      deep: false,
    },

    actualSplitCount: {
      handler(splitCount) {
        this.splitPayments = this.generateSplitObject(splitCount, []);
        this.splitDiscounts = this.generateSplitObject(splitCount, []);
        this.splitTotals = this.generateSplitObject(splitCount, {});
        this.settledSplitIndices = this.generateSplitObject(splitCount, false);
        this.selectedItemsBySplit = this.generateSplitObject(splitCount, []);
        this.initCustomSplit();
        this.splitPax = Array.from(new Array(splitCount)).fill(
          this.defaultPerSplitPax
        );
        this.discounts = [];

        setTimeout(() => {
          if (
            this.activeOrder.billDiscount &&
            (!this.isPaymentStep || !this.canSplit)
          ) {
            this.applyDiscount(this.activeOrder.billDiscount, true);
          }
        }, 20);
      },
      immediate: true,
    },

    ePaymentInvoices(newValues, oldValues) {
      const newPayments = differenceWith(newValues, oldValues, isEqual);

      if (newPayments.length) {
        newPayments.forEach((payment) => {
          // split bill
          if (this.actualSplitCount > 1) {
            this.targetSplitBillIndex = this.splitBills.findIndex(
              (bill) => bill.bill_num === payment.billNum
            );
          }

          let foundEPaymentOnSplitBill = this.splitPayments[
            this.targetSplitBillIndex
          ].find(
            (currentPayment) =>
              currentPayment?.externalId === payment.externalId
          );

          if (foundEPaymentOnSplitBill) {
            foundEPaymentOnSplitBill.status = payment.status;

            this.updateActivePaymentQR(payment);
          } else {
            this.addPayment(payment);
          }

          // non-split bill
          let foundEPayment = this.payments.find(
            (currentPayment) =>
              currentPayment?.externalId === payment.externalId
          );

          if (foundEPayment) {
            foundEPayment.status = payment.status;
          }
        });
      }
    },

    splitTotals: {
      handler(newValue, oldValue) {
        if (OFFLOAD.sqliteOffloadReceipt && USE_NEW_AMOUNT_DUE_FORMULA && Array.isArray(newValue)) {
          for (const [index, value] of newValue.entries()) {
            this.splitTotals[index].total = getAmountDue(value);
          }
        }
      },
      deep: true
    }
  },

  methods: {

    ...mapMutations([
      "setActiveOrderId",
      "clearActiveOrderId",
      "addSettlement",
      "clearPendingSettlementByOrderId",
      "updateOrder",
      "setPendingSettlements",
      "clearPendingSettlementByOrderId",
      "addOrUpdateDataContainer",
      "incrementLastBillNum",
      "incrementLastReceiptNum",
      "setLastBillNum",
      "setLatestOnlinePayment",
    ]),

    ...mapActions(["sqliteUpsertReceipt", "sqliteOffloadSync"]),

    roundToTwoDecimals(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },

    removeNonSplitDiscount(discountIndex) {
      this.discounts.splice(discountIndex, 1);
      this.splitDiscounts[0].splice(discountIndex, 1);
    },

    triggerOrderpanelLineItemActions(item) {
      if (!this.$refs.orders) return;
      this.$refs.orders.showItemAction(item);
    },

    async parseReceiptDetails() {
      try {
        let receiptResponse = await getReceiptDetails();
        let details = Object.values(receiptResponse.data).find(
          (d) => d.brand_id == this.activeBrandId
        );
        this.receiptDetails = details;
      } catch (error) {
        console.log(error);
      }
    },

    generateSplitObject(splitCount, value) {
      return Array.from(new Array(splitCount)).reduce((acc, _, index) => {
        acc[index] = cloneDeep(value);
        return acc;
      }, []);
    },

    initCustomSplit() {
      const oldCustomSplitOrders = map(this.customSplit, "orders");
      this.customSplit = map(
        this.generateSplitObject(this.actualSplitCount, {
          ...this.activeOrder,
          splits: undefined,
          orders: [],
          pax: this.defaultPerSplitPax,
        }),
        (s, i) => ({ ...s, bill_num: Number(s.bill_num) + Number(i) })
      );

      if (some(oldCustomSplitOrders, (o) => !isEmpty(o))) {
        forEach(oldCustomSplitOrders, (orders, index) => {
          if (index in this.customSplit) {
            this.customSplit[index].orders = orders;
          } else {
            if (!isEmpty(this.customSplit)) {
              this.customSplit[0].orders = [
                ...this.customSplit[0].orders,
                ...orders,
              ];
            }
          }
        });
      } else {
        if (!isEmpty(this.customSplit)) {
          this.customSplit[0].orders = cloneDeep(this.activeOrder.orders);
        }
      }
    },

    moveSelectionToSplit(targetIndex) {
      const movedItems = [];
      this.customSplit = forEach(this.customSplit, (_, sIndex) => {
        const toMove = pullAt(
          this.customSplit[sIndex].orders,
          this.selectedItemsBySplit[sIndex]
        );
        movedItems.push(...toMove);
      });

      this.customSplit[targetIndex].orders = [
        ...this.customSplit[targetIndex].orders,
        ...movedItems,
      ];

      this.clearSelection();
    },

    updateSelectionsByIndex(index, selection) {
      this.selectedItemsBySplit[index] = selection;
    },

    clearSelection() {
      this.selectedItemsBySplit = this.generateSplitObject(
        this.actualSplitCount,
        []
      );
    },

    parseTotalPayable() {
      this.$refs.orders.recomputeTotals();
      const { totals } = this.$refs.orders;
      this.paymentsBreakdown = totals;
      this.totalPayable = this.$filters.formatPrice(totals.total);
      if (!this.canSplit) {
        this.splitTotals[0] = cloneDeep(totals);
      }
    },

    updateTotalPayable(amount) {
      this.totalPayable = this.$filters.formatPrice(Number(amount));
    },

    addPayment(payment) {
      if (OFFLOAD.sqliteOffloadReceipt && window.hasEPayment) {
        payment.orderId = this.$route.params.orderId
      }

      if (
        !this.selectedPaymentMethods
          .map((pm) => pm?.name)
          .includes(payment.method) &&
        this.$refs.paymentModal.paymentMethods
      ) {
        this.selectedPaymentMethods.push(
          this.$refs.paymentModal.paymentMethods.find(
            (pm) => pm.name == payment.method || pm.type === payment.type
          )
        );
      }

      const existingCashIndex = this.splitPayments[
        this.targetSplitBillIndex
      ].findIndex(
        (p) => p.method.toLowerCase() == "cash" && p.method == payment.method
      );

      if (existingCashIndex > -1) {
        this.splitPayments[this.targetSplitBillIndex][
          existingCashIndex
        ].amount += Number(payment.amount);
      } else {
        this.splitPayments[this.targetSplitBillIndex].push(cloneDeep(payment));
      }

      this.isAddPaymentModalOpen = false;

      if (!this.canSplit) {
        const existingCashIndexNonSplit = this.payments.findIndex(
          (p) => p.method.toLowerCase() == "cash" && p.method == payment.method
        );

        if (existingCashIndexNonSplit > -1) {
          this.payments[existingCashIndexNonSplit].amount += Number(
            payment.amount
          );
        } else {
          this.payments.push(cloneDeep(payment));
        }
      }
    },

    removePayment(splitIndex, paymentIndex) {
      this.splitPayments[splitIndex].splice(paymentIndex, 1);
      this.splitPayments[splitIndex] = redistributeTenders(
        this.splitPayments[splitIndex],
        this.splitTotals[splitIndex].total
      );

      if (!this.canSplit && splitIndex === 0) {
        this.payments = cloneDeep(this.splitPayments[splitIndex]);
      }
    },

    removeDiscount(splitIndex, discountIndex) {
      this.splitDiscounts[splitIndex].splice(discountIndex, 1);
    },

    async processPayment() {
      this.parseTotalPayable();
      const { paymentsTotal } = this.$refs.orders;
      if (
        this.roundToTwoDecimals(paymentsTotal) <
        this.roundToTwoDecimals(this.totalPayable)
      ) {
        this.$swal.warning(
          "Outstanding balance must be cleared before payment"
        );
        return;
      }

      await this.tagActiveOrderAsSettled();

      if (!this.canSplit) {
        await this.postPaymentHandler(true, this.receiptArgs);
      }
    },

    async tagActiveOrderAsSettled() {
      const splits = isEmpty(this.activeOrder.splits)
        ? this.splitBills
        : this.activeOrder.splits;
      const orderPayload = {
        isSettled: true,
        orders: this.activeOrder.orders.map((o) => ({
          ...o,
          isMerged: false,
          product: {
            ...o.product,
            pricings: o.product.pricings.filter(
              (pr) =>
                pr.service_type_id ==
                (o.serviceTypeId || this.activeOrder.serviceTypeId) &&
                pr.service_channel_id ==
                (o.channelTypeId || this.activeOrder.channelTypeId || null)
            ),
            tax_data: o.product.tax_data.filter(
              (t) =>
                t.service_type ==
                (o.serviceName || this.activeOrder.serviceType) ||
                !t.service_type
            ),
          },
        })),
        splits: map(splits, (s, i) => {
          const settlement = this.settlements.find(
            (p) => p.bill_num === s.bill_num
          );
          return settlement
            ? {
              ...s,
              splits: undefined,
              isSettled: Boolean(settlement !== undefined),
              totals: this.splitTotals[i],
              discounts: this.splitDiscounts[i],
              receipt_num: settlement.receipt_num,
              payments: this.splitPayments[i],
              pax: this.splitPax[i],
              orders: s.orders.map((o) => ({
                ...o,
                isMerged: false,
                product: {
                  ...o.product,
                  pricings: o.product.pricings.filter(
                    (pr) =>
                      pr.service_type_id ==
                      (o.serviceTypeId || this.activeOrder.serviceTypeId) &&
                      pr.service_channel_id ==
                      (o.channelTypeId ||
                        this.activeOrder.channelTypeId ||
                        null)
                  ),
                  tax_data: o.product.tax_data.filter(
                    (t) =>
                      t.service_type ==
                      (o.serviceName || this.activeOrder.serviceType) ||
                      !t.service_type
                  ),
                },
              })),
            }
            : s;
        }),
        payments: cloneDeep(this.payments),
      };

      const getMaxSeries = (splits, key) => {
        return splits.reduce((max, split) => {
          if (split[key]) {
            return Math.max(max, split[key]);
          }
          return max;
        }, 0);
      };

      const lastBillNum = getMaxSeries(orderPayload.splits, "bill_num");
      orderPayload.bill_num = lastBillNum || orderPayload.bill_num;
      const lastReceiptNum = getMaxSeries(orderPayload.splits, "receipt_num");
      orderPayload.receipt_num = lastReceiptNum || orderPayload.receipt_num;

      seriesService.setLastBillNum(lastBillNum);
      seriesService.setLastReceiptNum(lastReceiptNum);

      if (OFFLOAD.sqliteOffloadReceipt) {
          orderPayload.settledAt = await posDateWithCurrentTime();
      }

      this.updateOrder({
        orderId: this.orderId,
        order: orderPayload,
      });

      if (this.activeOrder.tableMergedWith) {
        this.activeMergees.forEach((mergee) => {
          this.updateOrder({
            orderId: mergee._id,
            order: { isSettled: true },
          });
        });
      }

      if (OFFLOAD.sqliteOffloadReceipt) {
        this.sqliteUpsertReceipt({
          orderId: this.orderId,
          action: OFFLOAD_RECEIPT_ACTION.SETTLE_ORDER
        })
      }
    },

    async postPaymentHandler(force = false, receiptArgs = {}) {
      if (this.isPostPaymentProcessing) return;
      if (this.canSplit && this.settledSplitIndices.some((s) => s == false)) {
        this.$swal.warning("Please settle all bills before proceeding");
        return;
      }

      this.receiptArgs = receiptArgs;
      if (!this.canSplit && force !== true) {
        this.startSettleSplit(0);
        return;
      }

      this.isPostPaymentProcessing = true;
      this.clearPendingSettlementByOrderId(this.activeOrderId);
      if (!this.activeOrder.isSettled) {
        await this.tagActiveOrderAsSettled();
      }
      this.clearActiveOrderId();
      this.$router.push({ name: "home" });
    },

    async startPayment() {
      if (some(this.splitBills, (s) => isEmpty(s.orders))) {
        this.$swal.warning(
          "Empty bills are not allowed. Please move items or reduce split count"
        );
        return;
      }

      this.isPaymentStep = true;
      if (this.actualSplitCount == 1) {
        this.forceSingleBillUi = true;
      } else {
        seriesService.setLastBillNum(
          await seriesService.getLastBillNum() + (this.actualSplitCount - 1)
        );
      }

      this.$nextTick(() => this.saveSettlementState());
    },

    subtractSplit() {
      if (this.splitCount === 1) return;
      this.splitCount--;
    },

    addSplit() {
      this.splitCount++;
    },

    combineSplits() {
      this.splitCount = 1;
    },

    updateTotalsByIndex(index, totals) {
      this.splitTotals[index] = totals;
    },

    openPaymentModal(index) {
      this.parseTotalPayable();
      this.targetSplitBillIndex = index;
      this.isAddPaymentModalOpen = true;
    },

    openPaymentModalWithPreset(paymentMethodType) {
      if (window.enableOfflineMode && paymentMethodType.type === "epayment") {
        this.$swal.warning("Please switch to online mode to use this method.");
        return;
      }

      this.parseTotalPayable();
      this.presetPaymentMethodType = paymentMethodType.name;
      this.isAddPaymentModalOpen = true;
    },

    getSelectedPaymentObject(selectedPaymentObject) {
      this.selectedPaymentObject = selectedPaymentObject;
    },

    openDiscountModal(index) {
      if (this.checkIfHasLineItemDiscount(this.splitBills[index].orders)) {
        this.$swal.warning(
          "Cannot add discount to bill with line item discount"
        );
        return;
      }

      if (!isEmpty(this.splitDiscounts[index])) {
        this.$swal.warning("Cannot add more than one discount to a bill");
        return;
      }

      this.targetSplitBillIndex = index;
      this.isDiscountModalOpen = true;
    },

    applyDiscount(discountData, replace = false) {
      const pax = !this.canSplit
        ? this.activeOrderPax
        : this.splitPax[this.targetSplitBillIndex];
      const amount = calculateDiscountAmount(
        discountData,
        this.splitTotals[this.targetSplitBillIndex],
        pax
      );

      if (
        !replace &&
        find(this.splitDiscounts[this.targetSplitBillIndex], {
          ...discountData,
          amount,
        })
      ) {
        return;
      }

      if (replace) {
        if (!this.canSplit) {
          this.discounts = [];
        }

        this.splitDiscounts[this.targetSplitBillIndex] = [
          { ...discountData, amount },
        ];
      } else {
        this.splitDiscounts[this.targetSplitBillIndex].push({
          ...discountData,
          amount,
        });
      }

      if (!this.canSplit) {
        this.discounts.push({
          ...discountData,
          amount,
        });
      }

      this.isDiscountModalOpen = false;
    },

    startSettleSplit(index) {
      this.targetSplitBillIndex = index;
      this.isCustomerDetailsModalOpen = true;
    },

    postCustomerDetailsHandler(selectedOrder, customerDetails = null) {
      if (this.isPostCustomerDetail) return;
      this.isPostCustomerDetail = true;

      this.isCustomerDetailsModalOpen = false;
      this.settleBill(this.targetSplitBillIndex, customerDetails);
    },

    getLineItemDiscounts(index) {
      return this.splitBills[index].orders.reduce((ret, o) => {
        if (o.discount) {
          ret.push(o.discount);
        }

        return ret;
      }, []);
    },

    printAllSplitBills() {
      if (this.$refs.splitBills) {
        this.$refs.splitBills.forEach((split) =>
          split.emitPrintBill.call(split)
        );
      }
    },

    showReceipt(index, splitTotals) {
      let customOrder = {
        ...this.splitBills[index],
        billDiscounts: [...this.splitDiscounts[index]],
      };
      this.$refs.orders.showReceipt(customOrder, splitTotals);
    },

    async getLatestReceiptNum() {
      if (!(await checkCloudConnection())) return null;
      try {
        const response = await getLatestSeries();
        return response.data.receipt_num ? response.data.receipt_num + 1 : null;
      } catch (e) {
        console.log(e);
      }
    },

    async settleBill(index, customerDetails = null) {
      const payment = {
        orderId: this.activeOrder._id,
        payments: this.splitPayments[index],
        orders: [
          {
            ...this.splitBills[index],
            pax: this.splitPax[index],
            orders: this.splitBills[index].orders.map((o) => ({
              ...o,
              isMerged: false,
              product: {
                ...o.product,
                pricings: o.product.pricings.filter(
                  (pr) =>
                    pr.service_type_id ==
                    (o.serviceTypeId || this.activeOrder.serviceTypeId) &&
                    pr.service_channel_id ==
                    (o.channelTypeId ||
                      this.activeOrder.channelTypeId ||
                      null)
                ),
                tax_data: o.product.tax_data.filter(
                  (t) =>
                    t.service_type ==
                    (o.serviceName || this.activeOrder.serviceType) ||
                    !t.service_type
                ),
              },
            })),
          },
        ],
        breakdown: this.splitTotals[index],
        serviceType: this.activeOrder.serviceType,
        tableId: this.splitBills[index].tableId,
        discounts: this.splitDiscounts[index],
        customerDetails,
        originTerminalId: this.terminalId,
        isSync: false,
        change: 0,
        settledAt: toIsoString(new Date()),
      };

      const totalPaid = this.splitPayments[index].reduce((total, payment) => {
        return total + parseFloat(payment.amount);
      }, 0);

      const change = this.splitTotals[index].total - totalPaid;

      if (change < 0) {
        payment.change = Math.abs(change);
      }

      //add receipt_num and bill_num to settlement_state
      payment.bill_num = this.splitBills[index].bill_num;

      const lastReceiptNum = !OFFLOAD.sqliteOffloadMA33 && (this.isMultiTerminal || window.ENABLE_SERIES_TABLE)
        ? Math.max(
          (await this.getLatestReceiptNum()) ?? 0,
          await seriesService.getLastReceiptNum() + 1
        )
        : await seriesService.getLastReceiptNum() + 1;

      seriesService.setLastReceiptNum(lastReceiptNum);
      payment.receipt_num = lastReceiptNum;

      this.addSettlement(payment);

      // Save to LocalStorage for use in retry synchronization
      if (!OFFLOAD.sqliteOffloadReceipt) {
        this.upsertToLocalStorage(payment);
      }

      // add customer details to the orders state
      this.updateOrder({
        orderId: this.activeOrder._id,
        order: {
          customerDetails,
        },
      });
      this.settledSplitIndices[index] = true;
      let customOrder = null;

      if (this.canSplit) {
        this.payments.push(...this.splitPayments[index]);
        this.discounts.push(...this.splitDiscounts[index]);

        customOrder = {
          ...this.splitBills[index],
          splits: undefined,
          pax: this.splitPax[index],
          billDiscounts: [...this.splitDiscounts[index]],
        };

        this.receiptArgs = this.$refs.orders.generateReceiptArgs(
          mapValues(this.splitTotals[index], (t) =>
            this.$filters.formatPrice(t)
          ),
          customOrder
        );
      }

      let discountContainer = [];

      if (customOrder) {
        customOrder.billDiscounts?.forEach((d) => {
          discountContainer.push({
            discountAmount: d.amount,
            discountPax: d.discountQuantity,
            ...d.customerDetails[0],
            ...d.discount,
          });
        });
      } else if (this.activeOrder.billDiscount) {
        this.activeOrder.billDiscount.customerDetails.forEach((detail) => {
          discountContainer.push({
            discountAmount:
              this.activeOrder.billDiscount.amount /
              this.activeOrder.billDiscount.discountQuantity,
            discountPax: this.activeOrder.billDiscount.discountQuantity,
            ...detail,
            ...this.activeOrder.billDiscount.discount,
          });
        });
      } else {
        //get line item discount
        discountContainer.push(...this.getLineItemDiscounts(index));
      }

      let receiptArgs = {
        ...this.receiptArgs,
        receipt_num: payment.receipt_num,
        payments: !this.canSplit ? this.payments : this.splitPayments[index],
        customerDetails: customerDetails,
        discountContainer: discountContainer,
        mode: "settlement",
      };

      await this.$nextTick();
      this.saveSettlementState();
      await this.$nextTick();

      if (this.canSplit) {
        this.isPostCustomerDetail = false;
        await this.processPrint(index, receiptArgs);
        return;
      }

      if (this.isAllSettled) {
        if (!this.canSplit) {
          await this.processPrint(index, receiptArgs);
        }

        window.__showLoader();
        await this.$nextTick();
        await this.processPayment();
        await this.$nextTick();

        window.__hideLoader();
      }
    },

    upsertToLocalStorage(data) {
      if (this.terminalId != 1) return;

      try {
        const key = `${window.locationId}-salesReceipts`;

        const existingData = JSON.parse(localStorage.getItem(key)) || {};

        const newData = {
          bill_num: data.bill_num,
          receipt_num: data.receipt_num,
          payments: data.payments,
        };

        if (existingData[data.orderId]) {
          existingData[data.orderId].push(newData);
        } else {
          existingData[data.orderId] = [newData];
        }

        localStorage.setItem(key, JSON.stringify(existingData));
      } catch (error) {
        console.error("Error upserting data in localStorage:", error);
      }
    },

    async processPrint(targetIndex, receiptArgs) {
      const index = this.canSplit ? targetIndex : 0;
      let activeOrder = cloneDeep(this.activeOrder);

      if (OFFLOAD.sqliteOffloadPOSFE1300
        && ACTIVE_ACCOUNT_TYPE === ACCOUNT_TYPES.BM
        && !this.canSplit
        && !isEmpty(activeOrder.receiptMergedWith)) {
        activeOrder = await mergeAndUpdatePax(activeOrder);
      }

      const splitBills = this.canSplit ? cloneDeep(this.splitBills) : [activeOrder];
      const orPrintQuantity = Math.max(...this.selectedPaymentMethods.map(payment => payment.receipt_print_quantity));
      let printerData = await getPrinterData();
      let printConfig = {
        location_id: this.receiptDetails.location_id,
      };
      this.processCashDrawer(printerData);
      const locIds = JSON.parse(
        sessionStorage.getItem("new_bill_or_enabled_location_ids")
      );
      for (let i = 1; i <= orPrintQuantity; i++) {
        if (locIds?.includes(this.receiptDetails.location_id)) {
          const {
            data: { printer_details, printer_models },
          } = await getPrinterIps();
          printer_details
            .filter((p) => p.can_print_receipt === 1 || p.can_print_bill === 1)
            .forEach((printer) => {
              const printerModel = printer_models.find(
                (pm) => pm.id === printer.printer_model_id
              );
              const _epos = new Epos({
                ip: printer.ip_address,
                model: printerModel.printer_method,
                width: printer.paper_width,
              });
              _epos.print(
                window.BILL_OR,
                {
                  ...this.activeOrder,
                  receipt_num: receiptArgs.receipt_num,
                  is_paid: true,
                },
                window.STANDALONE_MODE
              );
            });
        } else {
          receiptArgs = {
            ...receiptArgs,
            copyHeader: this.getORCopyHeader(orPrintQuantity, i),
          };
          let receiptString = await getReceiptPrintString(
            splitBills[index],
            receiptArgs
          );
          let receiptItemString = await generateReceiptItemString(
            splitBills[index]
          );
          let printData = {
            print_type: "Receipt",
            printer_data: printerData,
            html: receiptString,
            mode: receiptArgs.mode,
          };
          print(printData, printConfig, receiptItemString, IS_PUC_LOCATION);
        }
      }
    },

    getORCopyHeader(orPrintQuantity, currentIndex) {
      const storeCopy = OR_PRINT_HEADERS.STORE_COPY;
      const customerCopy = OR_PRINT_HEADERS.CUSTOMER_COPY;
      if (orPrintQuantity > 1 && currentIndex === 2) {
        return customerCopy;
      } else if (orPrintQuantity > 1) {
        return storeCopy;
      } else {
        return "";
      }
    },

    processCashDrawer(printerDetails) {
      if (
        this.selectedPaymentMethods.filter((pm) => pm?.open_cash_drawer == 1)
          .length > 0
      ) {
        openCashDrawer(printerDetails);
      }
    },

    saveSettlementState() {
      this.setPendingSettlements({
        orderId: this.activeOrderId,
        settlement: {
          payments: this.payments,
          discounts: this.discounts,
          splitPayments: this.splitPayments,
          splitDiscounts: this.splitDiscounts,
          splitTotals: this.splitTotals,
          splitType: this.splitType,
          splitCount: this.splitCount,
          settledSplitIndices: this.settledSplitIndices,
          customSplit: this.customSplit,
          isPaymentStep: this.isPaymentStep,
          billCreatedAt: toIsoString(new Date()),
          originTerminalId: this.terminalId,
        },
      });

      const splits =
        this.activeOrder.splits ??
        this.splitBills.map((b, index) => ({
          ...b,
          bill_num: this.activeOrder.bill_num + index,
          splits: undefined,
        }));

      this.updateOrder({
        orderId: this.activeOrderId,
        order: {
          billCreatedAt: toIsoString(new Date()),
          splits: map(splits, (s, i) => {
            const settlement = this.settlements.find(
              (p) => p.bill_num === s.bill_num
            );
            //recompute totals
            return {
              ...s,
              totals: this.splitTotals[i],
              discounts: this.splitDiscounts[i],
              pax: this.splitPax[i],
              receipt_num: settlement?.receipt_num || null,
              isSettled: Boolean(settlement !== undefined),
              orders: s.orders.map((o) => ({
                ...o,
                isMerged: false,
                product: {
                  ...o.product,
                  pricings: o.product.pricings.filter(
                    (pr) =>
                      pr.service_type_id ==
                      (o.serviceTypeId || this.activeOrder.serviceTypeId) &&
                      pr.service_channel_id ==
                      (o.channelTypeId ||
                        this.activeOrder.channelTypeId ||
                        null)
                  ),
                  tax_data: o.product.tax_data.filter(
                    (t) =>
                      t.service_type ==
                      (o.serviceName || this.activeOrder.serviceType) ||
                      !t.service_type
                  ),
                },
              })),
            };
          }),
        },
      });
    },

    async loadSettlementState() {
      if (isEmpty(this.activePendingSettlements)) {
        return;
      }

      return new Promise((resolve) => {
        const deferredProperties = [
          "splitPayments",
          "splitDiscounts",
          "splitTotals",
          "settledSplitIndices",
        ];

        forEach(
          omit(this.activePendingSettlements, deferredProperties),
          (data, property) => {
            this[property] = data ? cloneDeep(data) : this[property];
          }
        );

        this.$nextTick(() => {
          forEach(
            pick(this.activePendingSettlements, deferredProperties),
            (data, property) => {
              this[property] = data ? cloneDeep(data) : this[property];
            }
          );

          if (this.actualSplitCount == 1) {
            this.forceSingleBillUi = true;
          }

          resolve();
        });
      });
    },

    checkIfHasLineItemDiscount(orders) {
      return orders.some((o) => !isEmpty(o.discount));
    },

    generatePaymentQRInfo(bill) {
      const billNum =
        this.actualSplitCount > 1
          ? this.splitBills[this.targetSplitBillIndex].bill_num
          : this.activeOrder.bill_num;

      const params = {
        orderId: this.orderId,
        bill: billNum,
        table: this.activeOrder.tableId ?? null,
        payment: bill.payment.name,
        amount: bill.amount,
        status: "PENDING",
        invoiceUrl: null,
        xendItPaymentChannel: bill.payment.hidden_label,
      };

      this.generatePaymentQR(params);

      this.printBill = bill.print;
    },

    qrGenerated(payment) {
      this.addPayment(payment);

      if (this.printBill) {
        const type = this.activeOrder.serviceType.includes("QSR")
          ? "qr"
          : "bill";
        this.printReceipt(
          type,
          this.activeOrder,
          this.paymentQRDetails.invoiceUrl
        );
      }
    },

    removePaymentInvoice(externalId) {
      this.cancelPaymentInvoice(externalId);
    },

    postRoomChargePayment(data) {
      const payment = {
        amount: data.totals.total,
        change: 0,
        exact_amount: data.totals.total,
        method: "Room Charge",
        type: "comp",
      };
      this.addPayment(payment);
    },

  },

  created() {
    this.startAutoRevalidation({
      status: PAYMENT_INVOICE_STATUSES.PENDING_AND_PAID,
    });
  },
};
</script>

<style scoped>
.payment-page {
  overflow: auto;
  max-height: calc(100vh - 86px);
}

.split-btn {
  text-transform: capitalize;
}

.hidden {
  display: none !important;
}
</style>
